.loading-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    flex-direction: row;

    .loading-text {
        font-size: 40px;
        padding: 10px;
        // box-shadow: 0 0 10px rgba(#000, 0.3);
    }
    .loading-logo {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
            width: 60px;
            height: 60px;
            left: 20px;
            top: 20px;
        }
        i {
            font-size: 100px;
            color: #fff;
        }
        img,
        i {
            position: absolute;
        }
    }
    .logo-inner {
        width: 100px;
        height: 100px;
        position: relative;
    }
}
