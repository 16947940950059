.app-nav-bar {
    background: #264876;
    align-items: center;
    height: 50px;
    min-height: 50px;
}

.nav-bar_logo {
    height: 100%;

    a {
        color: #fff;
        font-size: 12px;
        text-decoration: none;
    }
}
