@import 'node_modules/@fluentui/react/dist/sass/_References.scss';

body {
    margin: 0px !important;
    padding: 0;
    font-family: sans-serif;
    @include ms-bgColor-gray10;
}

body,
html {
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
