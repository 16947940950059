.blade--expanded {
    width: 235px !important;
}

.blade {
    width: 49px;
    padding-bottom: 5px;
    transition: width 0.15s;
}

.blade_expandButton {
    min-height: 30px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
